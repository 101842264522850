import React from 'react';
import { Smartphone, Target, Zap, Shield, Globe, Code } from 'lucide-react';
import { Button } from './components/ui/button';
import { Input } from './components/ui/input';
import './App.css';

function App() {
  return (
    <div className="app-container">
      <header className="header">
        <a href="#" className="logo">
          <Smartphone className="logo-icon" />
          <span className="logo-text">SinghGPT</span>
        </a>
        <nav className="nav">
          <a href="#services" className="nav-link">Services</a>
          <a href="#features" className="nav-link">Features</a>
          <a href="#contact" className="nav-link">Contact</a>
        </nav>
      </header>
      <main>
        <section className="hero">
          <div className="container">
            <h1 className="hero-title">Innovating Mobile Apps & Ad Tech</h1>
            <p className="hero-description">
              Building cutting-edge consumer mobile apps and digital advertising technology.
            </p>
            <div className="hero-buttons">
              <Button variant="primary">Get Started</Button>
              <Button variant="secondary">Learn More</Button>
            </div>
          </div>
        </section>
        <section id="services" className="services">
          <div className="container">
            <h2 className="section-title">Our Services</h2>
            <div className="services-grid">
              <div className="service-item">
                <Smartphone className="service-icon" />
                <h3 className="service-title">Consumer Mobile Apps</h3>
                <p className="service-description">
                  Intuitive and engaging mobile applications that drive user engagement.
                </p>
              </div>
              <div className="service-item">
                <Target className="service-icon" />
                <h3 className="service-title">Digital Ad Tech</h3>
                <p className="service-description">
                  Advanced advertising technology to reach the right audience and maximize ROI.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="features">
          <div className="container">
            <h2 className="section-title">Why Choose Us</h2>
            <div className="features-grid">
              <div className="feature-item">
                <Zap className="feature-icon" />
                <h3 className="feature-title">Fast Performance</h3>
                <p className="feature-description">Lightning-fast apps and ad delivery.</p>
              </div>
              <div className="feature-item">
                <Shield className="feature-icon" />
                <h3 className="feature-title">Secure & Reliable</h3>
                <p className="feature-description">Top-notch security for data and ad integrity.</p>
              </div>
              <div className="feature-item">
                <Globe className="feature-icon" />
                <h3 className="feature-title">Global Reach</h3>
                <p className="feature-description">Worldwide app distribution and ad network.</p>
              </div>
              <div className="feature-item">
                <Code className="feature-icon" />
                <h3 className="feature-title">Cutting-edge Tech</h3>
                <p className="feature-description">Leveraging the latest technologies.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="contact" className="contact">
          <div className="container">
            <p className="contact-description">
              Get in touch with us
            </p>
            <form className="contact-form">
              <Input type="email" placeholder="Enter your email" />
              <Button type="submit" variant="primary">
                →
              </Button>
            </form>
          </div>
        </section>
      </main>
      <footer className="footer">
        <div className="container">
          <p className="footer-text">© 2023 SinghGPT, LLC. All rights reserved.</p>
          <nav className="footer-nav">
            <a href="#" className="footer-link">Terms of Service</a>
            <a href="#" className="footer-link">Privacy</a>
          </nav>
        </div>
      </footer>
    </div>
  );
}

export default App;