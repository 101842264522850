import React from 'react';
import './button.css';

export const Button = ({ children, variant = 'default', type = 'button', ...props }) => {
  return (
    <button className={`button ${variant}`} type={type} {...props}>
      {children}
    </button>
  );
};
